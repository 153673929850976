const info = {
    // links: [
    //     {
    //         text: 'Home',
    //         url: '/'
    //     },
    //     {
    //         text: `Contact Us`,
    //         url: `/contact-us`,
    //     },
    //     {
    //         text: `About Us`,
    //         url: `/about-us`,
    //     },
    //     {
    //         text: `My Pharmacy`,
    //         url: `/my-pharmacy`,
    //     },
    //     {
    //         text: `Our Team`,
    //         url: `/our-team`,
    //     },
    // ],
}

export default info

